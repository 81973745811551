import { Card, styled, IconButton, CardActionArea, Dialog, DialogContent } from '@mui/material';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import React from 'react';

const CardWrapper = styled(Card)(({ theme }) => ({
    backgroundImage: `url('vid-1-thumbnail-updated.webp')`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    width: '1040px',
    height: '360px',
    [theme.breakpoints.down('sm')]: {
        width: '100%',
        padding: '0px',
    },
}));

const CardInner = styled(CardActionArea)(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '360px',
}));

const IFrame = styled('iframe')(({ theme }) => ({
    position: 'fixed',
    top: '12%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    [theme.breakpoints.down('sm')]: {
        width: '100%',
    },
}));

const VideoCard: React.FC = () => {
    const [expanded, toggleExpanded] = React.useState(false);
    return (
        <>
            <CardWrapper>
                <CardInner onClick={() => toggleExpanded(true)}>
                    <IconButton>
                        <PlayCircleIcon color='primary' sx={{ fontSize: '96px' }} />
                    </IconButton>
                </CardInner>
            </CardWrapper>
            <Dialog fullWidth={true} maxWidth='md' open={expanded} onClose={() => toggleExpanded(false)}>
                <DialogContent>
                    <IFrame height='480' src='https://www.youtube.com/embed/DkiZy264IvM?si=BFitZsAEm4hy61wD' title='Welcome to Synopify' frameBorder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share' allowFullScreen />
                </DialogContent>
            </Dialog>
        </>
    );
};

export default VideoCard;
