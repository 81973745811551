import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { List, ListItem, ListItemText, Toolbar, Typography, Button, ListItemAvatar, ListItemButton, Avatar, IconButton, Drawer, Collapse, ButtonBase, AppBar, Divider, useTheme } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from "react-oidc-context";
import { IdTokenClaims } from 'oidc-client-ts';
import CssBaseline from '@mui/material/CssBaseline';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Outlet } from 'react-router-dom';
import DropdownMenu from './components/header/Dropdown';
import './App.css';
import AppContext from './AppContext';

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const LogoTypography = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
        display: 'none',
    },
}));

const LogoTypographyMobile = styled(Typography)(({ theme }) => ({
    display: 'none',
    [theme.breakpoints.down('sm')]: {
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
    },
}));

const HeaderButton = styled(Button)(() => ({
    color: 'rgba(0,0,0,0.8)',
    textTransform: 'none',
}));

const ToolbarLink = styled(Link)<{ dark: boolean }>(({ dark, theme }) => ({
    textDecoration: 'none',
    color: 'rgba(0,0,0,0.8)',
}));

const DrawerIconDiv = styled(Box)(({ theme }) => ({
    display: 'none',
    [theme.breakpoints.down('sm')]: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
}));

const DrawerOpenIcon = styled(IconButton)(({ theme }) => ({
    display: 'none',
    [theme.breakpoints.down('sm')]: {
        display: 'flex',
    },
}));

const AppToolbar = styled(Toolbar)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
        justifyContent: 'flex-start',
    },
}));

const ToolbarList = styled(List)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexGrow: 0.1,
    [theme.breakpoints.down('sm')]: {
        display: 'none',
    },
}));

const SigninButton = styled(Button)(({ theme }) => ({
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'none',
}));

interface IMainLayoutProps {
    disableLinks?: boolean;
}

const MainLayout: React.FC<IMainLayoutProps> = ({ disableLinks }) => {
    const CHROME_LINK = 'https://chromewebstore.google.com/detail/synopify/cjambnblpmehbebdaedphlfglmlmedbn';
    const EDGE_LINK = 'https://microsoftedge.microsoft.com/addons/detail/synopify/nppacmljajjhpfiiodagfhkdnfohoena';

    const [anchorElOne, setAnchorElOne] = React.useState(null);
    const [anchorElTwo, setAnchorElTwo] = React.useState(null);

    const [drawer, openDrawer] = React.useState<boolean>(false);

    const [expandProducts, toggleExpandProducts] = React.useState<boolean>(false);
    const [expandSocials, toggleExpandSocials] = React.useState<boolean>(false);

    const handleClickOne = (event: any) => {
        setAnchorElOne(event.currentTarget);
    };

    const handleCloseOne = () => {
        setAnchorElOne(null);
    };

    const handleClickTwo = (event: any) => {
        setAnchorElTwo(event.currentTarget);
    };

    const handleCloseTwo = () => {
        setAnchorElTwo(null);
    };


    const [user, setUser] = React.useState<IdTokenClaims | undefined>(undefined);

    const auth = useAuth();

    React.useEffect(() => {
        if (auth?.isAuthenticated && auth?.user) {
            setUser(auth?.user?.profile);
        } else {
            setUser(undefined)
        }
    }, [auth]);

    const navigate = useNavigate();

    const { browser } = React.useContext(AppContext);
    const theme = useTheme();
    return (
        <Box>
            <CssBaseline />
            <AppBar position='fixed' sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, p: 0, backgroundColor: 'white' }} elevation={0}>
                <AppToolbar sx={{ height: 64, width: '95%', margin: 'auto' }} variant="dense">
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexGrow: 1 }}>
                        <DrawerIconDiv>
                            <ToolbarLink to='/' dark={false}>
                                <LogoTypographyMobile variant='h6'>
                                    <Avatar src='/logo/updated/96.png' alt='icon_96' sx={{ width: 28, height: 28 }} />
                                    Synopify
                                </LogoTypographyMobile>
                            </ToolbarLink>
                        </DrawerIconDiv>

                        <ToolbarLink to='/' dark={false}>
                            <LogoTypography variant='h6'>
                                <Avatar sx={{ width: 32, height: 32 }} src='/logo/updated/96.png' alt='icon_96' />
                                Synopify
                            </LogoTypography>
                        </ToolbarLink>
                        {!disableLinks && (
                            <ToolbarList sx={{ marginLeft: '60px' }}>
                                <ListItem>
                                    <HeaderButton onClick={handleClickOne}>
                                        <Typography sx={{ fontWeight: 'bold' }} variant='subtitle2'>
                                            Products
                                        </Typography>
                                        <ArrowDropDownIcon />
                                    </HeaderButton>
                                    <DropdownMenu anchorEl={anchorElOne} handleClose={handleCloseOne}>
                                        <List>
                                            <ToolbarLink to={browser === 'Edge' ? EDGE_LINK : CHROME_LINK} target='_blank' dark={false}>
                                                <ListItemButton>
                                                    <ListItemAvatar>
                                                        <Avatar src='/extension.png' />
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        primary='Extension'
                                                        primaryTypographyProps={{
                                                            fontWeight: 'bold',
                                                        }}
                                                        secondary={
                                                            <React.Fragment>
                                                                <Typography sx={{ display: 'inline' }} component='span' variant='body2' color='text.secondary'>
                                                                    Summarise any website to gain a quick <br /> understanding of the site and the company behind it
                                                                </Typography>
                                                                <Box sx={{ display: 'flex', alignItems: 'center', gap: theme.spacing(2), marginTop: theme.spacing(1) }}>
                                                                    <Avatar sx={{ width: 18, height: 18 }} alt='Chrome' src='chrome-logo-gray.png' />
                                                                    <Avatar sx={{ width: 18, height: 18 }} alt='Edge' src='edge-logo-gray.png' />
                                                                </Box>
                                                            </React.Fragment>
                                                        }
                                                    />
                                                </ListItemButton>
                                            </ToolbarLink>
                                        </List>
                                    </DropdownMenu>
                                </ListItem>
                                <ListItem>
                                    <HeaderButton onClick={handleClickTwo}>
                                        <Typography sx={{ fontWeight: 'bold' }} variant='subtitle2'>
                                            Follow us
                                        </Typography>
                                        <ArrowDropDownIcon />
                                    </HeaderButton>
                                    <DropdownMenu anchorEl={anchorElTwo} handleClose={handleCloseTwo}>
                                        <List>
                                            <ToolbarLink to='https://www.linkedin.com/company/synopify' target='_blank' dark={false}>
                                                <ListItemButton>
                                                    <ListItemAvatar>
                                                        <Avatar sx={{ height: 32, width: 32 }}>
                                                            <LinkedInIcon sx={{ color: 'white' }} />
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText primaryTypographyProps={{ variant: 'body2' }} primary='Linkedin' />
                                                </ListItemButton>
                                            </ToolbarLink>
                                            <ToolbarLink to='https://www.instagram.com/synopify.ai' target='_blank' dark={false}>
                                                <ListItemButton>
                                                    <ListItemAvatar>
                                                        <Avatar sx={{ height: 32, width: 32 }}>
                                                            <InstagramIcon sx={{ color: 'white' }} />
                                                        </Avatar>
                                                    </ListItemAvatar>

                                                    <ListItemText primaryTypographyProps={{ variant: 'body2' }} primary='Instagram' />
                                                </ListItemButton>
                                            </ToolbarLink>
                                        </List>
                                    </DropdownMenu>
                                </ListItem>
                                <ListItem>
                                    <ToolbarLink to='/contact' dark={false}>
                                        <HeaderButton>
                                            <Typography sx={{ fontWeight: 'bold' }} variant='subtitle2'>
                                                Contact us
                                            </Typography>
                                        </HeaderButton>
                                    </ToolbarLink>
                                </ListItem>
                            </ToolbarList>
                        )}
                    </Box>
                    <ToolbarList sx={{ flexGrow: 0 }}>
                        <ListItem>
                            <ToolbarLink to={browser === 'Edge' ? EDGE_LINK : CHROME_LINK} dark={false} target='_blank '>
                                <SigninButton sx={{ borderRadius: 100 }} color='secondary' variant='contained' disableElevation>
                                    <Avatar src={`/${browser.toLowerCase()}-logo-white.png`} sx={{ width: 18, height: 18, marginRight: theme.spacing(0.5) }} />
                                    Add to {browser}
                                </SigninButton>
                            </ToolbarLink>
                        </ListItem>
                        {user && (
                            <ListItem sx={{ width: '25%', margin: 'auto' }}>
                                <ButtonBase onClick={() => navigate('/user/profile')}>
                                    <Avatar sx={{ bgcolor: '#e8f4ff', fontWeight: 'bold', width: 32, height: 32 }} src={user?.picture}>
                                        {user?.given_name?.charAt(0)}
                                        {user?.family_name?.charAt(0)}
                                    </Avatar>
                                </ButtonBase>
                            </ListItem>
                        )}
                        {!user && (
                            <ListItem sx={{ width: '25%', margin: 'auto' }}>
                                <SigninButton onClick={() => auth.signinRedirect()} color='secondary' variant='text'>
                                    Sign in
                                </SigninButton>
                            </ListItem>
                        )}
                    </ToolbarList>
                    <DrawerOpenIcon onClick={() => openDrawer(!drawer)}>
                        <MenuIcon fontSize='medium' />
                    </DrawerOpenIcon>
                </AppToolbar>
                <Divider />
            </AppBar>
            <Box component='main'>
                <DrawerHeader />
                <Drawer anchor='top' open={drawer} onClose={() => openDrawer(false)}>
                    <DrawerHeader />
                    <List sx={{ maxWidth: '360px', minWidth: '360px' }}>
                        <ListItemButton onClick={() => toggleExpandProducts(!expandProducts)}>
                            <ListItemText primary='Products' primaryTypographyProps={{ sx: { fontWeight: 'bold' }, variant: 'subtitle1' }} />
                            {expandProducts ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={expandProducts} timeout='auto' unmountOnExit>
                            <List component='div' disablePadding>
                                <ToolbarLink to={browser === 'Edge' ? EDGE_LINK : CHROME_LINK} target='_blank' dark={true}>
                                    <ListItemButton sx={{ pl: 4 }}>
                                        <ListItemAvatar>
                                            <Avatar src='/extension.png' />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary='Extension'
                                            primaryTypographyProps={{
                                                fontWeight: 'bold',
                                            }}
                                            secondary={
                                                <React.Fragment>
                                                    <Typography sx={{ display: 'inline' }} component='span' variant='body2' color='text.secondary'>
                                                        Summarise any website to gain a quick understanding of the site and the company behind it
                                                    </Typography>
                                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: theme.spacing(2), marginTop: theme.spacing(1) }}>
                                                        <Avatar sx={{ width: 18, height: 18 }} alt='Chrome' src='chrome-logo-gray.png' />
                                                        <Avatar sx={{ width: 18, height: 18 }} alt='Edge' src='edge-logo-gray.png' />
                                                    </Box>
                                                </React.Fragment>
                                            }
                                        />
                                    </ListItemButton>
                                </ToolbarLink>
                            </List>
                        </Collapse>
                        <ListItemButton onClick={() => toggleExpandSocials(!expandSocials)}>
                            <ListItemText primary='Follow us' primaryTypographyProps={{ sx: { fontWeight: 'bold' }, variant: 'subtitle1' }} />
                            {expandSocials ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={expandSocials} timeout='auto' unmountOnExit>
                            <List component='div' disablePadding>
                                <ToolbarLink to='https://www.linkedin.com/company/synopify' target='_blank' dark={true}>
                                    <ListItemButton>
                                        <ListItemAvatar>
                                            <Avatar sx={{ width: 32, height: 32 }}>
                                                <LinkedInIcon sx={{ color: 'white' }} />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primaryTypographyProps={{ variant: 'body2' }} primary='Linkedin' />
                                    </ListItemButton>
                                </ToolbarLink>
                                <ToolbarLink to='https://www.instagram.com/synopify.ai' target='_blank' dark={true}>
                                    <ListItemButton>
                                        <ListItemAvatar>
                                            <Avatar sx={{ width: 32, height: 32 }}>
                                                <InstagramIcon sx={{ color: 'white' }} />
                                            </Avatar>
                                        </ListItemAvatar>

                                        <ListItemText primaryTypographyProps={{ variant: 'body2' }} primary='Instagram' />
                                    </ListItemButton>
                                </ToolbarLink>
                            </List>
                        </Collapse>
                        <ListItemButton>
                            <ToolbarLink to='/contact' dark={true}>
                                <ListItemText primaryTypographyProps={{ sx: { fontWeight: 'bold' }, variant: 'subtitle1' }} primary='Contact us' />
                            </ToolbarLink>
                        </ListItemButton>
                    </List>
                </Drawer>
                <Outlet context={{ user }} />
            </Box>
        </Box>
    );
};

export default MainLayout;
