import { Typography, Button, List, ListItemIcon, ListItemText, Box, Avatar } from '@mui/material';
import { styled } from '@mui/material';
import { Link } from 'react-router-dom';

export const OpeningHeader = styled('div')(({ theme }) => ({
  width: '100%',
  margin: 'auto',
//   backgroundImage: 'url("/layered-waves-haikei.svg")',
//   backgroundRepeat: 'no-repeat',
//   backgroundPosition: 'bottom',
//   backgroundSize: 'cover',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    textAlign: 'center',
    padding: theme.spacing(0.5),
    paddingTop: theme.spacing(4)
  },
}));

export const OpeningHeaderInner = styled('div')(({ theme }) => ({
  textAlign: 'left',
  width: '90%',
  margin: 'auto',
  padding: '40px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    padding: '0px',
    width: '100%',
    textAlign: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));

export const HowItWorksInnerLeft = styled('div')(({ theme }) => ({
  width: '80%',
  margin: 'auto',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    marginBottom: theme.spacing(4),
  },
}));

export const HowItWorks = styled('div')(({ theme }) => ({
  textAlign: 'left',
  backgroundColor: 'white',
  padding: '60px',
  // background: `url('wave-haikei.svg') no-repeat center center`,
  // backgroundSize: 'cover',
  [theme.breakpoints.down('sm')]: {
    padding: '0px',
  },
}));

export const OtherBrowserLink = styled(Link)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
    textDecoration: 'none',
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

export const Text2Audio = styled('div')(({ theme }) => ({
  textAlign: 'left',
  background: `linear-gradient(to bottom right, white, #dde4f0)`,
  padding: '60px',
  [theme.breakpoints.down('sm')]: {
    padding: '20px',
  },
}));

export const Text2AudioInner = styled(Box)(({ theme }) => ({
  width: '70%',
  margin: 'auto',
  display: 'flex',
  justifyContent: 'flex-start',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    textAlign: 'center',
  },
}));

export const Text2AudioLeft = styled(List)(({ theme }) => ({
  width: '70%',
  textAlign: 'left',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  gap: theme.spacing(12),
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    textAlign: 'center',
    justifyContent: 'space-around',
    gap: theme.spacing(4),
  },
}));

export const ChromeDescriptionText = styled(Typography)(({ theme }) => ({
  color: 'rgba(0, 0, 0, 0.7)',
  lineHeight: '2',
  width: '100%',
  margin: 'auto',
  marginBottom: '60px',
  paddingRight: '20px',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    marginTop: '20px',
    marginBottom: '20px',
    paddingRight: '0px',
  },
}));

export const BenefitsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  gap: '40px',
  marginTop: '40px',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));

export const Benefits = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  textAlign: 'left',
  width: '90%',
  margin: 'auto',
  padding: '60px',
  [theme.breakpoints.down('sm')]: {
    textAlign: 'center',
    width: '100%',
    margin: 'auto',
    justifyContent: 'center',
  },
}));

export const BenefitsImage = styled('img')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

export const SecondDiv = styled('div')(({ theme }) => ({
  backgroundColor: '#1b1e24',
  color: 'white',
  width: '100%',
  padding: theme.spacing(0),
  paddingTop: theme.spacing(8),
  paddingBottom: theme.spacing(8),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1),
  },
}));

export const ThirdDiv = styled('div')(({ theme }) => ({
  backgroundColor: 'white',
  width: '100%',
  textAlign: 'center',
  paddingTop: theme.spacing(8),
  paddingBottom: theme.spacing(8),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

export const ThirdDivInner = styled('div')(({ theme }) => ({
  width: '70%',
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  gap: '40px',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    margin: 'auto',
  },
}));

export const PrimaryText = styled('span')(({ theme }) => ({
  color: theme.palette.primary.dark,
}));

export const UnderlineText = styled('span')(({ theme }) => ({
  textDecoration: 'underline',
  textDecorationColor: theme.palette.primary.main,
}));

export const HeaderText = styled(Typography)<{ color?: string, underline?: boolean }>(({ theme, color, underline = true }) => ({
  textDecoration: underline ? 'underline' : 'none',
  textDecorationColor: theme.palette.primary.main,
  color: color ?? theme.palette.text.primary,
  fontWeight: 'bold',
  fontSize: '34px',
  lineHeight: 1,
  [theme.breakpoints.down('sm')]: {
    fontSize: '24px',
  },
}));

export const ListItemHeaderText = styled(ListItemText)<{ color?: string, underline?: boolean }>(({ theme, color, underline = true }) => ({
  textDecoration: underline ? 'underline' : 'none',
  textDecorationColor: theme.palette.primary.main,
  color: color ?? theme.palette.text.primary,
  fontWeight: 'bold',
  fontSize: '34px',
  lineHeight: 1,
  [theme.breakpoints.down('sm')]: {
    fontSize: '24px',
    display: 'none'
  },
}));

export const ListItemHeaderTextMobile = styled(ListItemText)<{ color?: string, underline?: boolean }>(({ theme, color, underline = true  }) => ({
  textDecoration: underline ? 'underline' : 'none',
  textDecorationColor: theme.palette.primary.main,
  color: color ?? theme.palette.text.primary,
  fontWeight: 'bold',
  lineHeight: 1,
  display: 'none',
  [theme.breakpoints.down('sm')]: {
    fontSize: '26px',
    display: 'block'
  },
}));

export const ChromeButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  fontWeight: 'bold',
  fontSize: '22px',
  color: 'white',
  padding: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    fontSize: '18px',
  },
}));

export const Benefit = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  width: '40%',
  textAlign: 'center',
  justifyContent: 'center',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    margin: 'auto',
    alignItems: 'center',
  },
}));

export const BenefitDescription = styled(Typography)(({ theme }) => ({
  width: '80%',
  margin: 'auto',
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    fontSize: '12px'
  },
}));

export const HowItWorksContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '40px',
  padding: '20px',
  [theme.breakpoints.down('sm')]: {
    padding: '4px',
  },
}));

export const HowItWorksList = styled(List)(({ theme }) => ({
  width: '70%',
  textAlign: 'left',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    textAlign: 'center',
  },
}));

export const MainHeaderTextWeb = styled(Typography)(({ theme }) => ({
  width: '80%',
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

export const MainHeaderTextMobile = styled(Typography)(({ theme }) => ({
  display: 'none',
  fontSize: '34px',
  lineHeight: 1,
  [theme.breakpoints.down('sm')]: {
    display: 'block',
    width: '100%',
    fontSize: '26px'
  },
}));

export const BulletPoint = styled(ListItemIcon)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

export const BulletPointText = styled(ListItemText)(({ theme }) => ({
  textAlign: 'left',
  [theme.breakpoints.down('sm')]: {
    textAlign: 'center',
    display:'none',
  },
}));

export const BulletPointTextMobile = styled(ListItemText)(({ theme }) => ({
  textAlign: 'left',
  display:'none',
  [theme.breakpoints.down('sm')]: {
    textAlign: 'center',
    display:'block',
  },
}));

export const HowItWorksInner = styled('div')(({ theme }) => ({
  width: '70%',
  margin: 'auto',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    textAlign: 'center',
  },
}));

export const ChipsContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    gap: theme.spacing(1),
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
    },
}));

export const BenefitAvatar = styled(Avatar)(({ theme }) => ({
    background: 'none',
    margin: 'auto',
    width: 96,
    height: 96,
    [theme.breakpoints.down('sm')]: {
        width: 72,
        height: 72
    },
}))

export const ParagraphText = styled(Typography)(({ theme }) => ({
    fontSize: '20px',
    width: '80%',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
        fontSize: '14px',
        width: '100%'
    },
}));
