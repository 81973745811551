import React from 'react';
import { Card, CardContent, Typography, styled } from '@mui/material';
import Footer from '../components/Footer';

const BoldText = styled(Typography)(({ theme }) => ({
    fontWeight: theme.typography.fontWeightBold,
}));

const CardContainerWrapper = styled(CardContent)(({ theme }) => ({
    width: '60%',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
        width: '100%'
    },
}));

const HeaderText = styled(Typography)(({ theme }) => ({
    fontSize: '34px',
    [theme.breakpoints.down('sm')]: {
        fontSize: '24px',
    },
}));

const LastUpdatedText = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        textAlign: 'center'
    },
}));

const PrivacyPolicy: React.FC = () => {
    return (
        <>
            <Card elevation={0}>
                <CardContainerWrapper>
                    <HeaderText gutterBottom sx={{ textAlign: 'center' }}>
                        Privacy Policy
                    </HeaderText>

                    <LastUpdatedText variant='caption' paragraph>
                        Last Updated: 29/12/2024
                    </LastUpdatedText>

                    <Typography variant='subtitle2' paragraph>
                        This Privacy Policy describes how Synopify collects, uses, and protects user information. Synopify is committed to safeguarding the privacy of its users and ensuring compliance with relevant data protection laws.
                    </Typography>

                    <BoldText variant='h6' gutterBottom>
                        1. Collecting and Using Your Personal Data
                    </BoldText>

                    <Typography variant='body1' paragraph>
                        <strong>1.1 Information Collection</strong>
                    </Typography>

                    <Typography variant='body2' paragraph>
                        <strong>Personal Data</strong>
                    </Typography>

                    <Typography variant='subtitle2' paragraph>
                        When using our service, we may request certain personal information to contact or identify you.<br />
                        This information may include, but is not limited to, your email address and name.<br />
                    </Typography>

                    <Typography variant='body2' paragraph>
                        <strong>Usage Data</strong>
                    </Typography>

                    <Typography variant='subtitle2' paragraph>
                        Automatically collected when using the service. This includes details such as the pages you visit on our service as well as the date and time of your visit.
                    </Typography>

                    <Typography variant='body2' paragraph>
                        <strong>Data Collection</strong>
                    </Typography>

                    <Typography variant='subtitle2' paragraph>
                        All usage information collected is stored in the Amazon US data center.<br />
                        Registration data will also be saved on Synopify servers in the Amazon US data center.<br />
                        If you choose to convert your web analysis to audio speech, these speech files will be saved on Synopify servers inside the Amazon US data center that then get automatically deleted after 48 hours.<br />
                    </Typography>

                    <Typography variant='body1' paragraph>
                        <strong>1.2 Sharing of Information</strong>
                    </Typography>

                    <Typography variant='body2' paragraph>
                        <strong>Data Sharing</strong>
                    </Typography>

                    <Typography variant='subtitle2' paragraph>
                        We may use some third party services, such as Google analytics to collect some usage data which is shared to those services.<br />
                        By choosing to use our service you are sharing information such as the websites you visit with AI, you agree to share that data with AI and the organization behind those AIs.<br />
                    </Typography>

                    <Typography variant='body2' paragraph>
                        <strong>Third-Party Authentication</strong>
                    </Typography>

                    <Typography variant='subtitle2' paragraph>
                        You can register for Synopify using your Google or Facebook account. This process securely verifies your identity with Google or Facebook without Synopify storing your login credentials
                    </Typography>

                    <BoldText variant='h6' gutterBottom>
                        2. Data Security
                    </BoldText>

                    <Typography variant='body2' paragraph>
                        <strong>Data Handling</strong>
                    </Typography>

                    <Typography variant='subtitle2' paragraph>
                        Data is transferred to servers through HTTPS.<br />
                        Synopify has strict restrictions on your data. No one will be able to access your data unless you request us to diagnose data related issues for you.<br />
                        We may get stats information of your data, for example, how many new web analysis the service has per day etc.<br />
                        We may use third party services like Google Analytics to collect some usage data.<br />
                        We will not sell your data to any third party.<br />
                        You can choose to delete your account and remove all data stored in our servers.<br />
                    </Typography>

                    <BoldText variant='h6' gutterBottom>
                        3. Changes to the Privacy Policy
                    </BoldText>
                    <Typography variant='subtitle2' paragraph>
                        Synopify may update this Privacy Policy periodically. Users will be notified of any changes through the Synopify website.
                    </Typography>

                    <BoldText variant='h6' gutterBottom>
                        4. Consent to Data Processing
                    </BoldText>
                    <Typography variant='subtitle2' paragraph>
                        By using the Synopify browser extension, users consent to the processing of information as outlined in this Privacy Policy.
                    </Typography>

                    <BoldText variant='h6' gutterBottom>
                        Contact Us
                    </BoldText>
                    <Typography variant='subtitle2' paragraph>
                        If you have any questions about this Privacy Policy, please contact us at{' '}
                        <a href='mailto:contact@synopify.io' target='_blank' style={{ textDecoration: 'none' }} rel='noreferrer'>
                            contact@synopify.io
                        </a>
                    </Typography>
                    <Typography variant='body1' paragraph>
                        <a href='https://www.flaticon.com/authors/vectorsmarket15' title='icons' target='_blank' rel='noreferrer'>
                            Icons created by vectorsmarket15 - Flaticon
                        </a>
                    </Typography>
                </CardContainerWrapper>
            </Card>
            <Footer />
        </>
    );
};

export default PrivacyPolicy;
