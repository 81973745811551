import React from 'react';
import { List, TextField, Typography, IconButton, Avatar, Card, Divider, ListItem, ListItemText, Box, useTheme } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { alpha, styled } from '@mui/material/styles';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import '../App.css';

const HeaderBar = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '95%',
    margin: 'auto',
}));

const CardContainer = styled(Card)(({ theme }) => ({
    height: '70vh',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
        display: 'none',
    },
}));

const CardDisplayUI = styled(Box)(() => ({
    borderTop: `1px solid rgba(51, 255, 187, 0.7)`,
    backgroundColor: '#1b1e24',
    width: '100%',
    margin: 'auto',
    textAlign: 'left',
    overflow: 'auto',
    height: '68vh',
}));

interface ICard {
    children: React.ReactNode;
}

const CardDisplay: React.FC<ICard> = ({ children }) => {
    return <CardDisplayUI>{children}</CardDisplayUI>;
};

const URLTextField = styled(TextField)(({ theme }) => ({
    width: '100%',
    color: 'white',
    margin: theme.spacing(1),
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: '#1A2027',
        border: '1px solid',
        borderColor: '#2D3843',
        color: 'white',
        fontSize: 12,
        width: '100%',
        padding: '5px 6px',
        transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
        fontFamily: ['-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'Roboto', '"Helvetica Neue"', 'Arial', 'sans-serif', '"Apple Color Emoji"', '"Segoe UI Emoji"', '"Segoe UI Symbol"'].join(','),
        '&:focus': {
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
        },
    },
}));

const Analysis = () => {
    const url = 'https://synopify.io/';

    const theme = useTheme();

    const summary =
        [
            '- The text describes a browser extension that provides AI-generated summaries of websites to give users quick insights. Key features/benefits highlighted',
            '- Generates clear, concise summaries of sites to save time vs reading full content',
            '- Analyzes key information and insights from documents instantly',
            '- Can process documents in batches for efficiency',
            '- Aimed at helping users avoid wasting time surfing sites to understand them The text also provides contact information for those interested in learning more or making inquiries.',
            '- Overall, the purpose is to promote a tool that leverages AI to summarize webpages and documents to save time for users.'
        ];

    const breakdown = ['- Synopify offers a browser extension that provides AI-driven summaries and important information about a website', '- They are a tech startup specialising in AI-powered solutions in large document summarisation and generative AI assistance', '- Founded in 2024'];

    return (
        <CardContainer className='app-demo-container' elevation={5}>
            <HeaderBar>
                <Avatar sx={{ width: 24, height: 24 }} src='logo/updated/96.png' alt='icon_96' />

                <URLTextField variant='outlined' value={url} />
                <IconButton sx={{ color: 'white' }}>
                    <MenuOpenIcon />
                </IconButton>
            </HeaderBar>
            <CardDisplay>
                <Accordion defaultExpanded disableGutters={true}>
                    <AccordionSummary
                        expandIcon={<ArrowDropDownIcon sx={{ color: 'white' }} />}
                        aria-controls={`page-summary-content`}
                        id={`page-summary-id`}
                        sx={{ bgcolor: '#1b1e24' }}
                    >
                        <Typography sx={{ color: 'white' }} variant='caption' fontWeight={theme.typography.fontWeightBold}>Page Summary</Typography>
                    </AccordionSummary>
                    <Divider color={theme.palette.primary.main} />
                    <AccordionDetails sx={{ bgcolor: '#1b1e24' }}>
                        <List dense={true} sx={{ lineHeight: 2 }}>
                            {summary?.map((sentence, index) => (
                                <ListItem key={`page-summary-sentence-${index}`}>
                                    <ListItemText sx={{ color: 'white' }} primary={sentence} primaryTypographyProps={{ variant: 'caption' }} />
                                </ListItem>
                            ))}
                        </List>
                    </AccordionDetails>
                </Accordion>
                <Accordion defaultExpanded disableGutters={true}>
                    <AccordionSummary
                        expandIcon={<ArrowDropDownIcon sx={{ color: 'white' }} />}
                        aria-controls={`website-breakdown-content`}
                        id={`website-breakdown-id`}
                        sx={{ bgcolor: '#1b1e24' }}
                    >
                        <Typography sx={{ color: 'white' }} variant='caption' fontWeight={theme.typography.fontWeightBold}>About https://synopify.io</Typography>
                    </AccordionSummary>
                    <Divider color={theme.palette.primary.main} />
                    <AccordionDetails sx={{ bgcolor: '#1b1e24' }}>
                        <List dense={true} sx={{ lineHeight: 2 }}>
                            {breakdown?.map((sentence, index) => (
                                <ListItem key={`website-breakdown-sentence-${index}`}>
                                    <ListItemText sx={{ color: 'white' }} primary={sentence} primaryTypographyProps={{ variant: 'caption' }} />
                                </ListItem>
                            ))}
                        </List>
                    </AccordionDetails>
                </Accordion>
            </CardDisplay>
        </CardContainer>
    );
};

export default Analysis;
