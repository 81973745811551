import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { createBrowserRouter, RouterProvider, redirect } from 'react-router-dom';
import './App.css';
import appTheme from './AppTheme';
import Home from './home/Home';
import Privacy from './privacy/Privacy';
import Contact from './contact';
import AppContext from './AppContext';
import AuthCallback from './signin/callback';
import NotFound from './not-found';
import UserProfile from './user-profile';
import Uninstall from './uninstall';
import MainLayout from './MainLayout';

const router = createBrowserRouter([
    {
        path: '/',
        element: <MainLayout />,
        children: [
            {
                path: '/',
                element: <Home />,
            },
            {
                path: '/privacy',
                element: <Privacy />,
            },
            {
                path: '/contact',
                element: <Contact />,
            },
            {
                path: '/signin',
            },
            {
                path: '/auth/callback',
                element: <AuthCallback />,
            },
            {
                path: '/user',
                loader: () => redirect('/user/profile'),
            },
            {
                path: '/user/profile',
                element: <UserProfile />,
            },
            {
                path: '/uninstall',
                element: <Uninstall />,
            },
            {
                path: '*',
                element: <NotFound />,
            },
        ]
    },
    {

    }
])

function App() {
    const [browser, setBrowser] = React.useState<string>('');

    React.useEffect(() => {
        const detectBrowser = () => {
            const userAgent = navigator.userAgent;
            if (userAgent.includes("Edg/")) {
                setBrowser("Edge");
            } else {
                setBrowser("Chrome");
            }
        };

        detectBrowser();
    }, []);

    return (
        <ThemeProvider theme={appTheme}>
            <AppContext.Provider value={{ browser }}>
                <RouterProvider router={router} />
            </AppContext.Provider>
        </ThemeProvider>
    );
}

export default App;
