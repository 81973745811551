import { createContext } from 'react';

export interface IContext {
    browser: string;
}

const AppContext = createContext<IContext>({
    browser: ''
});

export default AppContext;
