import React from 'react';
import { Typography, Box, Avatar, Fade, Divider, List, ListItem, useTheme, Chip } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import FactCheckIcon from '@mui/icons-material/FactCheckOutlined';
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';
import PaidIcon from '@mui/icons-material/PaidOutlined';
import StarIcon from '@mui/icons-material/Star';
import { Link } from 'react-router-dom';
import '../App.css';
import Footer from '../components/Footer';
import AnalysisDemo from './AnalysisWidget';
import VideoCard from './VideoCard';
import {
    OpeningHeader,
    HowItWorksList,
    OpeningHeaderInner,
    PrimaryText,
    UnderlineText,
    HowItWorksInnerLeft,
    BulletPointText,
    ChromeButton,
    HowItWorksInner,
    ThirdDivInner,
    BulletPoint,
    MainHeaderTextWeb,
    MainHeaderTextMobile,
    HowItWorks,
    SecondDiv,
    Benefits,
    BenefitsImage,
    HeaderText,
    BenefitsWrapper,
    Benefit,
    BenefitDescription,
    ThirdDiv,
    Text2Audio,
    Text2AudioInner,
    Text2AudioLeft,
    ChipsContainer,
    BulletPointTextMobile,
    ListItemHeaderText,
    ListItemHeaderTextMobile,
    BenefitAvatar,
    HowItWorksContainer,
    ParagraphText
} from './components';
import AppContext from '../AppContext';

const Home = () => {
    const CHROME_LINK = 'https://chromewebstore.google.com/detail/synopify/cjambnblpmehbebdaedphlfglmlmedbn';
    const EDGE_LINK = 'https://microsoftedge.microsoft.com/addons/detail/synopify/nppacmljajjhpfiiodagfhkdnfohoena';

    const theme = useTheme();

    const keyPoints = [
        {
            message: 'Concise and comprehensive breakdowns and summaries of any web page',
            icon: <AutoStoriesOutlinedIcon sx={{ color: theme.palette.primary.dark }} />
        },
        {
            message: 'Key facts and information about the company behind the website',
            icon: <FactCheckIcon sx={{ color: theme.palette.primary.dark }} />
        },
        {
            message: 'Free to use',
            icon: <PaidIcon sx={{ color: theme.palette.primary.dark }} />
        }
    ];

    const benefits = [
        {
            header: 'Time Saving',
            description: 'Save time by reading only the important information',
            img: '/benefits/color/clock.png',
        },
        {
            header: 'Extractable Insights',
            description: 'Be presented with the key information and insights from your web page',
            img: '/benefits/color/analysis.png',
        },
        {
            header: 'AI Assistance',
            description: 'Allow AI to read through large websites for you',
            img: '/benefits/color/brain.png',
        },
    ];

    const howItWorks = [
        `Using the URL and Title of the current browser tab to extract insights about the webpage's content and provide background information about the associated company`,
        'Summarise all content on the web page into a concise and comprehensive overview',
        'Present a list of key information around the website as well as a condensed paragraph summarising the content on the current page',
    ];

    const { browser } = React.useContext(AppContext);

    const compatibleBrowsers = ['chrome', 'edge'];

    return (
        <Box className='first-div-class'>
            <Fade in={true} timeout={1000}>
                <div>
                    <OpeningHeader>
                        <div id="circle-1"></div>
                        <div id="circle-2"></div>
                        <OpeningHeaderInner>
                            <HowItWorksInnerLeft>
                                <MainHeaderTextWeb variant='h3' sx={{ color: 'rgba(0, 0, 0, 0.8)', fontWeight: 'bold' }}>
                                    Your <PrimaryText>AI</PrimaryText> Powered Browser <UnderlineText>Assistant</UnderlineText>
                                </MainHeaderTextWeb>
                                <MainHeaderTextMobile sx={{ color: 'rgba(0, 0, 0, 0.8)', fontWeight: 'bold' }}>
                                    Your <PrimaryText>AI</PrimaryText> Powered Browser <UnderlineText>Assistant</UnderlineText>
                                </MainHeaderTextMobile>
                                <List dense sx={{ marginTop: '10px', marginBottom: '10px' }}>
                                    {keyPoints.map((point, idx) => (
                                        <ListItem key={`key-point-${idx + 1}`}>
                                            <BulletPoint>
                                                {point.icon}
                                            </BulletPoint>
                                            <BulletPointText primaryTypographyProps={{ variant: 'subtitle1', fontWeight: idx === 2 ? 'bold' : 'regular' }}>{point.message}</BulletPointText>
                                            <BulletPointTextMobile primaryTypographyProps={{ variant: 'caption', fontWeight: idx === 2 ? 'bold' : 'regular' }}>{point.message}</BulletPointTextMobile>
                                        </ListItem>
                                    ))}
                                </List>
                                <Link to={browser === 'Edge' ? EDGE_LINK : CHROME_LINK} target='_blank'>
                                    <ChromeButton color='secondary' variant='contained'>
                                        <Avatar src={`/${browser.toLowerCase()}-logo-white.png`} sx={{ width: 32, height: 32, marginRight: theme.spacing(1) }} />
                                        Add to {browser}
                                    </ChromeButton>
                                </Link>
                                <br />
                                <ChipsContainer>
                                    {browser === 'Chrome' && <Chip icon={<StarIcon color='warning' />} label='5-Star rating on Chrome' />}
                                    {compatibleBrowsers.filter((b) => b !== browser.toLowerCase()).map((browser) =>
                                        <Link key={`${browser}-chip-link`} to={browser === 'edge' ? EDGE_LINK : CHROME_LINK} target='_blank'>
                                            <Chip
                                                clickable
                                                icon={<Avatar src={`/${browser}-logo-gray.png`}
                                                    sx={{ width: 18, height: 18 }} />}
                                                label="Compatible"
                                            />
                                        </Link>
                                    )}
                                </ChipsContainer>
                            </HowItWorksInnerLeft>
                            <VideoCard />
                        </OpeningHeaderInner>
                    </OpeningHeader>
                    <HowItWorks>
                        <HowItWorksInner>
                            <HowItWorksContainer>
                                <HowItWorksList>
                                    <ListItem sx={{ color: 'rgba(0, 0, 0, 0.8)' }}>
                                        <ListItemHeaderText underline={false} primaryTypographyProps={{ variant: 'h4', fontWeight: theme.typography.fontWeightBold, textAlign: 'center' }}>
                                            <UnderlineText>Summaries</UnderlineText> In Your <PrimaryText>Sidebar</PrimaryText>
                                        </ListItemHeaderText>
                                        <ListItemHeaderTextMobile underline={false} primaryTypographyProps={{ variant: 'h5', fontWeight: theme.typography.fontWeightBold, textAlign: 'center' }}>
                                            <UnderlineText>Summaries</UnderlineText> In Your <PrimaryText>Sidebar</PrimaryText>
                                        </ListItemHeaderTextMobile>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemHeaderText underline={false} primaryTypographyProps={{ variant: 'h5', fontWeight: theme.typography.fontWeightBold, textAlign: 'center' }}>
                                            How it works
                                        </ListItemHeaderText>
                                        <ListItemHeaderTextMobile underline={false} primaryTypographyProps={{ variant: 'h6', fontWeight: theme.typography.fontWeightBold, textAlign: 'center' }}>
                                            How it works
                                        </ListItemHeaderTextMobile>
                                    </ListItem>
                                    <Divider />
                                    {howItWorks.map((point, index) => (
                                        <ListItem key={`how-it-works-point-${index + 1}`}>
                                            <BulletPoint>
                                                <CircleIcon color='secondary' sx={{ fontSize: '12px' }} />
                                            </BulletPoint>
                                            <BulletPointText primaryTypographyProps={{ variant: 'subtitle1' }}>{point}</BulletPointText>
                                            <BulletPointTextMobile primaryTypographyProps={{ variant: 'caption' }}>{point}</BulletPointTextMobile>
                                        </ListItem>
                                    ))}
                                </HowItWorksList>
                                <AnalysisDemo />
                            </HowItWorksContainer>
                        </HowItWorksInner>
                    </HowItWorks>
                    <Text2Audio>
                        <Text2AudioInner>
                            <Text2AudioLeft>
                                <HeaderText underline={false} style={{ fontWeight: theme.typography.fontWeightBold, textAlign: 'center' }}>
                                    <UnderlineText>Convert</UnderlineText> <PrimaryText>Text</PrimaryText> To <PrimaryText>Speech</PrimaryText>
                                </HeaderText>
                                <Box>
                                    <ParagraphText style={{ textAlign: 'center', fontWeight: theme.typography.fontWeightLight }}>
                                        Avoid the need to read through long paragraphs. Receive an audio narrative instead as Synopify will convert your web summaries into speech
                                    </ParagraphText>
                                    <Typography variant='subtitle1' sx={{ textAlign: 'center', fontWeight: theme.typography.caption, width: '80%', margin: 'auto', marginTop: '20px' }}>
                                        Currently only supports English
                                    </Typography>
                                </Box>
                            </Text2AudioLeft>

                            <BenefitsImage src='robot_512.png' alt='robot' />
                        </Text2AudioInner>
                    </Text2Audio>
                </div>
            </Fade>

            <SecondDiv>
                <Benefits>
                    <div style={{ width: '80%', margin: 'auto' }}>
                        <HeaderText sx={{ textAlign: 'center' }} color='rgba(255, 255, 255, 0.9)'>
                            Benefits
                        </HeaderText>

                        <BenefitsWrapper>
                            {benefits.map((benefit) => (
                                <Benefit key={`benefit-${benefit.header}`}>
                                    <BenefitAvatar src={benefit.img} />
                                    <Typography variant='subtitle1' sx={{ fontWeight: theme.typography.fontWeightBold, fontSize: '20px', color: 'rgba(255,255,255,0.8)' }}>
                                        {benefit.header}
                                    </Typography>
                                    <BenefitDescription variant='body1'>{benefit.description}</BenefitDescription>
                                </Benefit>
                            ))}
                        </BenefitsWrapper>
                    </div>
                </Benefits>
            </SecondDiv>
            <ThirdDiv>
                <ThirdDivInner>
                    <HeaderText color='rgba(0, 0, 0, 0.9)'>
                        Want to learn more?
                    </HeaderText>
                    <ParagraphText sx={{ lineHeight: '2' }}>
                        For any further information or enquiries please contact us to find out more
                    </ParagraphText>
                    <Link to='/contact' target='_blank'>
                        <ChromeButton color='secondary' variant='contained' sx={{ fontSize: '24px' }}>
                            Contact us
                        </ChromeButton>
                    </Link>
                </ThirdDivInner>
            </ThirdDiv>
            <Footer />
        </Box>
    );
};

export default Home;
